<template>
  <div>
    <div class="modal fade text-center" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
      <div class="modal-dialog modalImage">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title font-weight-bold" id="normalizationsHistoryLabel"> <i class="fas fa-plug"></i> Atualização da Api Key (Gupshup)</h5>
            </div>
            <div class="modal-body d-flex justify-content-center">
              <p>
                Tem certeza que deseja atualizar a Api Key?
              </p> 
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" @click="closeModal('#confirmModal')"><b>Cancelar</b></button>
              <button class="btn btn-success h-25 mt-auto" @click="updateGupshupApiKey($event)">Atualizar Api Key</button>
            </div>
        </div>
      </div>
    </div>

      <div class="content-wrapper">
        <section class="content-header p-1 px-2">
          <div class="div-header container-fluid">
            <div style="border-bottom: 1px solid #0001" class="row mb-2">
              <div class="col-sm-12 d-flex justify-content-between align-items-center">
                <h4 style="color: #0007" class="mt-3">
                  <i class="fas fa-recycle"></i>
                  Atualização da Api Key (Gupshup)
                </h4>
              </div>
            </div>
          </div>
        </section>

        <div class="container-fluid">
          <div class="row align-items-end">
            <div class="col-12">
              <div class="card">
                <div class="card-header d-flex align-items-center">
                  <h3 class="card-title font-weight-bold mr-2"> 
                    <i class="fas fa-key"></i> 
                    Update Api Key
                  </h3>
                  <button class="info" data-toggle="tooltip" data-placement="right" title="Funcionalidade para atualizar a ApiKey das empresas utilizado nas integração da Gupshup."><font-awesome-icon icon="fa-solid fa-circle-info" /></button>
                </div>
                <div class="card-body p-0">
                  <div class="content">
                    <form>
                      <div class="label col">
                        <div class="row form-group">
                          <div class="col-lg-4 mb-2">
                            <div class="form-check form-switch">
                              <div class="custom-control custom-switch">
                                <input type="checkbox"  @click="toggleCheckbox" class="custom-control-input" id="updateOnlyOneCompany">
                                <label class="custom-control-label" for="updateOnlyOneCompany">Atualizar somente uma Empresa</label>            
                              </div>
                            </div>
                          </div>
                          <div v-if="updateOnlyOneCompany" class="col-lg-3 mb-2">
                            <input
                              v-model="companyId"
                              type="text"
                              name="companyId"
                              :class="['form-control', { 'border-danger': hasErrorCompany }]"
                              placeholder="Informe o ID da Empresa"
                            />
                          </div>
                          <div class="col-lg-3 mb-2">
                            <input
                              v-model="apiKey"
                              type="text"
                              name="apiKey"
                              :class="['form-control', { 'border-danger': hasErrorApiKey }]"
                              placeholder="Informe a Nova ApiKey"
                            />
                          </div>
                          <div class="col-lg-2">
                            <button 
                              class="btn btn-sm btn-outline-success ml-2" 
                              @click.prevent="checkForm($event)" 
                              data-bs-target="#confirmModal"
                              :disabled="startUpdate"
                            >
                              Atualizar ApiKey
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import helpers from '../../../assets/helpers/index'

export default {
  name: "index-normalizations",
  data() {
    return {
      helpers,
      apiKey: null,
      companyId: null,
      startUpdate: false,
      hasErrorApiKey: false,
      hasErrorCompany: false,
      updateOnlyOneCompany: false,
    }
  },

  watch: {
    apiKey: function() {
      this.hasErrorApiKey = false;
    },
    companyId: function() {
      this.hasErrorCompany = false;
    }
  },

  methods: {
    toggleCheckbox() {
      this.updateOnlyOneCompany = !this.updateOnlyOneCompany;
      if (!this.updateOnlyOneCompany) {
        this.companyId = null;
      }
    },

    closeModal(modal) {
        $(modal).modal('toggle');
        this.startUpdate = false;
    },

    checkForm() {

      if (this.updateOnlyOneCompany && !this.companyId) {
        this.hasErrorCompany = true;
        this.$toasted.global.defaultError({msg: "O campo ID da Empresa é Obrigatório!"});
        return;
      }

      if (!this.apiKey) {
        this.hasErrorApiKey = true;
        this.$toasted.global.defaultError({msg: "O campo Api Key é Obrigatório!"});
        return;
      }

      $('#confirmModal').modal('toggle');
    },

    async updateGupshupApiKey() {

      this.$gupshup_api_key_update.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
      const url = !this.updateOnlyOneCompany ? `api-key/update-all-/${this.apiKey}` : `api-key/update-one/${this.apiKey}/company/${this.companyId}`
      this.startUpdate = true;
      await this.$gupshup_api_key_update
        .get(url)
				.then(response => {
          this.apiKey = null;
          this.companyId = null;
          $('#confirmModal').modal('toggle');
          this.$toasted.global.defaultSuccess({ msg: response.data.message, duration: 5000 });
          setTimeout(() => { this.startUpdate = false; }, 5000) 
				})
				.catch(function (error) {
          self.$toasted.global.defaultError({ msg: "Erro ao atualiza a ApiKey." });
          this.startUpdate = false;
				});
    },
  },

  created() {
    document.title = 'Tallos Admin - Normalizações';
  },
}
</script>

<style scoped>
.content-wrapper {
  background-color: #fff;
}

button {
  padding: 1vh;
  font-weight: bold;
}

.content {
  padding: 20px;
}

.info {
  all: unset;
}

body {
  text-align: center;
  background: #51C3A0; 
  padding: 50px
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #101010;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>
